<template>
  <b-overlay :show="!secondment || editLoading" rounded="sm" :style="{ minHeight: '100vh' }">
    <div v-if="secondment" class="d-flex flex-row secondment-card-custom">
      <div class="w-100 flex-shrink-0 offcanvas-desktop">
        <div class="navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-around"
          role="tablist">
          <template v-for="(card, idx) in cardsList">
            <div v-if="card.canShow" :key="idx" class="navi-item mb-2">
              <router-link :is="card.isDisabled ? 'span' : 'router-link'" exact-active-class="active"
                class="navi-link py-4" :class="{
    'cursor-not-allowed': card.isDisabled,
    'cursor-pointer': !card.isDisabled,
    disabled: card.isDisabled,
  }" :data-tab="idx" data-toggle="tab" role="tab" aria-selected="false" :to="{ name: card.routeName }">
                <span class="navi-icon mr-2">
                  <i :class="card.icon"></i>
                </span>
              </router-link>
            </div>
          </template>
        </div>
      </div>

      <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
        <div class="card card-custom card-stretch">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <country-flag class="mr-3" :country-iso="secondment.country"></country-flag>
              <div>
                <div v-if="secondment.clients.length" class="font-weight-bold font-size-h4 text-dark-75">
                  {{ client.name }}
                </div>
                <span class="text-capitalize text-muted">{{ this.$t(countriesMap[secondment.country]?.name) }}</span>
              </div>
            </div>

            <div class="mt-7">
              <div class="d-flex align-items-center justify-content-between mb-2 font-weight-bolder">
                {{ $t("Status") }}:
                <span class="label label-primary label-inline font-weight-bolder text-capitalize label-lg"
                  :class="{ 'label-warning': status == 'draft', 'label-success': status != 'draft' }">{{ status
                  }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2 font-weight-bolder">
                {{ $t("Created On") }}:
                <span class="font-weight-normal">{{
    secondment.created ? DateService.format(secondment.created, localFormatDate) : ""
  }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between font-weight-bolder">
                {{ $t("Last Update") }}:
                <span class="font-weight-normal">{{
    secondment.updated ? DateService.format(secondment.updated, localFormatDate) : ""
  }}</span>
              </div>
            </div>

            <div v-if="status == 'draft'" class="mt-7">
              <router-link class="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4" :to="{
    name: 'edit-secondment',
    params: { secondmentId: secondmentId },
  }">
                {{ $t("Edit assignment") }}
              </router-link>
            </div>

            <div class="py-9">
              <div class="d-flex align-items-center justify-content-between mb-2 font-weight-bolder">
                {{ $t("Date") }}:
                <div class="d-flex align-items-center">
                  <div class="font-weight-normal">
                    {{ secondment.date_start ? DateService.format(secondment.date_start, localFormatDate) : "" }}
                  </div>
                  <span class="svg-icon svg-icon-sm svg-icon-dark mx-1 font-weight-normal">
                    <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
                  </span>
                  <div class="font-weight-normal">
                    {{ secondment.date_end ? DateService.format(secondment.date_end, localFormatDate) : "" }}
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between font-weight-bolder">
                {{ $t("Assignment Duration ") }} ({{ $t("Days") }}):
                <span class="label label-light-dark label-rounded label-inline">
                  {{ secondmentSumDays(secondment.date_end, secondment.date_start) }}</span>
              </div>
            </div>

            <div v-if="status != 'draft'">
              <div v-if="secondmentProgressValue > -1" class="py-2">
                <div>
                  <p class="text-center font-weight-bold">{{ $t("Assignment") }} {{ $t("Progress") }}:</p>
                  <b-progress :max="progressData.max" height="1.5rem">
                    <b-progress-bar :value="progressData.value"> </b-progress-bar>
                  </b-progress>
                  <p class="text-center font-weight-bold">
                    {{ ((progressData.value / progressData.max) * 100).toFixed(2) }}%
                  </p>
                </div>
              </div>
            </div>
            <div v-if="hasManager">
              <div class="separator separator-dashed mb-10 mt-4"></div>
              <div class="d-flex align-items-center">
                <Avatar :avatar-image="current_manager[0].avatar" :avatar-text="`${current_manager[0].name}`" />
                <div class="ml-3">
                  <div class="font-weight-bolder">{{ $t("Assignment Manager") }}:</div>
                  <div class="font-weight-bold" :style="{ wordBreak: 'break-word' }">
                    {{ current_manager[0].name }}
                  </div>
                </div>
              </div>
              <div v-if="current_manager[0].email != null">
                <div class="d-flex align-items-center justify-content-between mb-2 font-weight-normal mt-4">
                  {{ $t("Email") }}:
                  <span class="text-muted w-75">{{ current_manager[0].email }}</span>
                </div>
              </div>
              <div v-if="current_manager[0].phone != null"
                class="d-flex align-items-center justify-content-between mb-2 font-weight-normal">
                {{ $t("Phone") }}:
                <span class="text-muted">{{ current_manager[0].phone }}</span>
              </div>
              <div class="separator separator-dashed mb-10 mt-4"></div>
            </div>

            <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">

              <template v-for="(card, idx) in cardsList">
                <div v-if="card.canShow" :key="idx" v-b-tooltip.hover class="navi-item mb-2" :title="card.tooltip">
                  <router-link :is="card.isDisabled ? 'span' : 'router-link'" exact-active-class="active"
                    class="navi-link py-4" :class="{
    'cursor-not-allowed': card.isDisabled,
    'cursor-pointer': !card.isDisabled,
    disabled: card.isDisabled,
  }" :data-tab="idx" data-toggle="tab" role="tab" aria-selected="false" :to="{ name: card.routeName }">
                    <span class="navi-icon">
                      <i :class="card.icon"></i>
                    </span>
                    <span class="navi-text font-size-lg">{{ card.name }}</span>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8">
        <div class="card card-custom">
          <router-view :key="$route.fullPath" v-bind="currentDetailComponentProps"
            v-on="currentDetailComponentEvents"></router-view>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import SecondmentService from "@/core/services/secondment/secondment.service";
import DateService from "@/core/services/date.service";
import EmployeeService from "@/core/services/employee/employee.service";
import TravelPlansService from "@/core/services/secondment/secondment-travelplan.service";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { backendErrorSwal } from "@/core/helpers/swal";
import { SERVICE_TYPE } from "@/core/abstractions/secondment";
import icons from "@/core/config/icons";
import { successToast } from "@/core/helpers";
export default {
  components: {
    CountryFlag,
  },
  data() {
    return {
      editLoading: false,
      secondment: null,
      tabIndex: 0,
      DateService: DateService,
      isSaving: false,
      icons,
      localFormatDate: "D MMM YYYY",
      current_manager: [],
      expand: 'clients.address,clients.vat,clients.registered_office,employees.availability_data'
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat", "isApStaff"]),
    ...mapGetters("constants", ["countriesMap"]),

    secondmentDays() {
      return DateService.enumerateDates(this.secondment.date_start, this.secondment.date_end);
    },

    secondmentProgressValue() {
      return this.secondmentDays.findIndex(e => e === DateService.format(new Date(), "YYYY-MM-DD"));
    },

    progressData() {
      return {
        max: this.secondmentDays.length - 1,
        value: this.secondmentProgressValue > 0 ? this.secondmentProgressValue : 0,
      };
    },

    secondmentId() {
      return this.$route.params.secondmentId;
    },

    cardsList() {
      // Maybe all this info can be put inside the routes? How to pass props and catch events then?
      return [
        {
          routeName: "detail-secondment-information",
          name: this.$t("Information"),
          icon: "fas fa-info",
          canShow: true,
          isDisabled: false,
          props: {
            secondment: this.secondment,
            isSaving: this.isSaving,
          },
          events: {
            "update-information": this.onUpdateInformation,
            "update-status": status => this.updateSecondment({ status }),
          },
          tooltip: "",
        },
        {
          routeName: "declarations-of-detachment",
          name: this.$t("Declarations of Detachment"),
          icon: "flaticon2-document",
          canShow: true,
          isDisabled: false,
          props: {
            secondment: this.secondment,
            isSaving: this.isSaving,
          },
          tooltip: "",
        },
        {
          routeName: "detail-secondment-employees",
          name: this.$t("Employees"),
          icon: "fas fa-users",
          canShow: true,
          isDisabled: false,
          props: {
            secondment: this.secondment,
            service: this.activitiesByClient[0]?.type ?? SERVICE_TYPE.CLIENT,
            isSaving: this.isSaving,
            resetToggle: this.isSaving,
          },
          events: {
            "update-employees": this.onUpdateEmployees,
          },
          tooltip: "",
        },
        {
          routeName: "detail-secondment-client",
          name: this.$t("Client"),
          icon: "fas fa-user-tie",
          canShow: true,
          isDisabled: false,
          props: {
            isIntragroupWork: this.isIntragroupWork,
            dateStart: this.secondment.date_start,
            dateEnd: this.secondment.date_end,
            client: this.client,
            isSaving: this.isSaving,
            country: this.secondment.country,
            service: this.activitiesByClient[0]?.type ?? SERVICE_TYPE.CLIENT,
          },
          events: {
            "update-client": this.onUpdateClient,
          },
          tooltip: "",
        },
        {
          routeName: "detail-secondment-activities",
          name: this.$t("Activities"),
          icon: "fas fa-briefcase",
          canShow: true,
          isDisabled: !this.client,
          props: {
            secondmentActivities: this.activitiesByClient,
            isSaving: this.isSaving,
            client: this.client,
          },
          events: {
            "update-activities": this.onUpdateActivities,
          },
          tooltip: "",
        },
        {
          routeName: "detail-secondment-contractor",
          name: this.$t("Contractor"),
          icon: "fas fa-building",
          canShow: true,
          isDisabled:
            !this.client?.activities[0]?.type ||
            (this.client?.activities[0]?.type && this.client?.activities.find(el => el?.type !== "contract_work")) ||
            !this.client,
          props: {
            dateStart: this.secondment.date_start,
            dateEnd: this.secondment.date_end,
            contractor: this.contractor,
            isSaving: this.isSaving,
          },
          events: {
            "update-contractor": this.onUpdateContractor,
          },
          tooltip: !this.isContractWork ? this.$t("Select contract work in activities for choosing a contractor") : "",
        },
        {
          routeName: "detail-secondment-suppliers",
          name: this.$t("Suppliers"),
          icon: "fas fa-parachute-box",
          canShow: true,
          isDisabled: !this.client,
          props: {
            dateStart: this.secondment.date_start,
            dateEnd: this.secondment.date_end,
            suppliers: this.suppliers,
            isSaving: this.isSaving,
          },
          events: {
            "update-suppliers": this.onUpdateSuppliers,
          },
          tooltip: "",
        },
        {
          routeName: "detail-secondment-documents",
          name: this.$t("Employees Documents"),
          icon: "flaticon2-document",
          canShow: true,
          isDisabled: false,
          props: {
            secondment: this.secondment,
            dateStart: this.secondment.date_start,
            dateEnd: this.secondment.date_end,
            suppliers: this.suppliers,
            isSaving: this.isSaving,
          },
          events: {
            "update-suppliers": this.onUpdateSuppliers,
          },
          tooltip: "",
        },
        {
          routeName: "detail-secondment-travel-plans",
          name: this.$t("Travel Plans"),
          icon: "fas fa-plane",
          canShow: true,
          isDisabled: !this.hasTravelPlans,
          props: {
            travelPlans: this.secondment?.travel_plans ?? [],
            secondmentEmployees: this.secondment?.employees ?? [],
            country: this.secondment.country,
            clientAddressId: this.client.address.id,
            client: this.client
          },
          events: {
            "update-plans": this.onPlansUpdate,
          },
          tooltip: "",
        },
        {
          routeName: "detail-secondment-history",
          name: this.$t("History"),
          icon: "fas fa-history",
          canShow: true,
          isDisabled: false,
          props: {
            secondment: this.secondment,
          },
          events: {},
          tooltip: "",
        },
        {
          routeName: "detail-secondment-reports",
          name: this.$t("History Risk Reports"),
          icon: "flaticon2-document",
          canShow: true,
          isDisabled: false,
          props: {
            secondment: this.secondment,
          },
          events: {},
          tooltip: "",
        },
        {
          routeName: "detail-secondment-orders",
          name: this.$t("Related orders"),
          icon: "flaticon2-document",
          canShow: true,
          isDisabled: false,
          props: {
            secondment: this.secondment,
            secondmentEmployees: this.secondment?.employees ?? [],
          },
          events: {},
          tooltip: "",
        }
      ];
    },

    currentDetail() {
      return this.cardsList.find(item => item.routeName == this.$route.name);
    },

    currentDetailComponentProps() {
      return this.currentDetail?.props;
    },

    currentDetailComponentEvents() {
      return this.currentDetail?.events;
    },

    // Can only have one client at the moment.
    client() {
      if (!this.secondment.clients || !this.secondment.clients.length) return null;
      return this.secondment.clients[0];
    },

    contractor() {
      // At the moment only one contractor can be selected.
      return this.client?.contractors[0];
    },

    suppliers() {
      return this.client?.partners ?? [];
    },

    activitiesByClient() {
      return this.client?.activities ?? [];
    },

    isIntragroupWork() {
      return this.activitiesByClient[0]?.type == "intragroup";
    },

    hasTravelPlans() {
      return !!this.secondment?.travel_plans.length ?? false;
    },

    isContractWork() {
      if (!this.client) return false;

      const activities = this.client.activities;

      if (!activities || !activities?.length) return false;

      return !!activities.find(act => act.type == "contract_work");
    },

    status() {
      return this.secondment.status;
    },

    hasManager() {
      return this.current_manager.length > 0;
    },
  },

  async mounted() {
    this.setBreadcrumb([
      { title: this.$t("Assignments") },
      { title: this.$t("Details") },
    ]);
    await this.init();
  },

  beforeDestroy() {
    this.$store.commit("wizard/SET_EDIT_LOADING_STATE", false);
    this.editLoading = false;
  },

  methods: {
    ...mapActions(["setBreadcrumb"]),
    ...mapActions("wizard", ["setEdit"]),
    async goToEdit() {
      this.editLoading = true;
      await this.setEdit(this.secondment);
      this.$router.push({ name: "create-secondment", query: { mode: "edit" } });
    },
    async init() {
      await SecondmentService.getOne({ id: this.secondmentId, expand: this.expand, omit: 'reports,history'}).then(response => {
        let secondment = response.data;

        // se è presente il travel plans lo inizializo
        if (secondment?.travel_plans) {
          secondment.travel_plans = secondment.travel_plans.map(tp => {
            tp.travel_days = tp.travel_days.map(td => {
              td.places = td.places.sort((a, b) => a.order - b.order);
              return td;
            });
            tp.employees = secondment.employees.filter(emp => tp.employees.includes(emp.id))
            return tp;
          });
        }

        return secondment;
      }).then(secondment => {
        this.secondment = secondment;
        // Ritorno il current manager
        return this.secondment.employees.filter(el => el.manager == true);
      }).then(currentManager => {
        if (currentManager.length > 0) {
          EmployeeService.getOne(currentManager[0].employee).then(res => {
            currentManager[0]["email"] = res.data.persona.email;
            currentManager[0]["phone"] = res.data.persona.phone;
          });
        }
        this.current_manager = currentManager;
      }).catch(err => {
        // Nel caso in cui non sia autorizzato l'errore è comunque 404
        if (err.response.status === 404) {
          this.$router.push({ name: '404' }); //unauthorized
          return
        }
        backendErrorSwal(err, err?.response?.data?.detail);
      });
    },

    async onPlansUpdate() {
      try {
        const r = await TravelPlansService.makeTravelPlansRequestBody(this.secondment.travel_plans);
        await this.updateSecondment({ travel_plans: r });
      } catch (err) {
        console.log(err);
        backendErrorSwal(err, err?.response?.data?.detail);
      }
    },

    async updateSecondment(body) {
      this.isSaving = true;
      // this.$store.commit("quickActions/setLoading", true);
      try {
        this.secondment = await SecondmentService.update(body, this.secondment.id, {
          returnConvertedTravelPlans: true,
        });
        successToast(this.$t("Assignment updated successfully."));
      } catch (err) {
        console.error(err);
        backendErrorSwal(err, err?.response?.data?.detail);
      } finally {
        this.isSaving = false;
        // this.$store.commit("quickActions/setLoading", false);
      }
    },

    async onUpdateEmployees(employees) {
      await this.updateSecondment({ employees });
    },

    onUpdateSuppliers(suppliers) {
      this.updateSecondment({
        clients: [{ id: this.client.id, partners: suppliers }],
      });
    },

    onUpdateClient(client) {
      const body = { clients: [] };

      if (client) {
        body.clients.push(client);
      }

      this.updateSecondment(body);
    },

    onUpdateContractor(contractor) {
      const client = {
        id: this.client.id,
        contractors: [],
      };

      if (contractor) {
        client.contractors.push(contractor);
      }

      const body = {
        clients: [client],
      };

      this.updateSecondment(body);
    },

    onUpdateActivities({ activities, service }) {
      const newActivities = [
        {
          activities: [],
          description: "",
          type: service,
        },
      ];

      if (activities && activities.length) {
        newActivities[0].activities = [...activities];
      }

      let clients = _.cloneDeep(this.secondment.clients);
      const client = clients.find(c => c.client == this.client?.client);
      if (!client) return;
      client.activities = newActivities;

      this.updateSecondment({ clients });
    },

    onUpdateInformation({ country, status, date_start, date_end }) {
      const body = { country, status, date_start, date_end };
      this.updateSecondment(body);
    },

    secondmentSumDays(date_end, date_start) {
      return DateService.diff(date_end, date_start) + 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.secondment-card-custom {
  flex-wrap: wrap;
}

@media screen and (min-width: 991.98px) {
  .secondment-card-custom {
    flex-wrap: nowrap;
  }

  .offcanvas-desktop {
    display: none;
  }
}

.disabled {
  color: #999 !important;
}

.info-label {
  min-width: 15ch;
}

.country-flag {
  min-width: 75px !important;
  max-width: 75px !important;
  max-height: 75px !important;
}
</style>
