var render = function render(){
  var _vm$countriesMap$_vm$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    style: {
      minHeight: '100vh'
    },
    attrs: {
      "show": !_vm.secondment || _vm.editLoading,
      "rounded": "sm"
    }
  }, [_vm.secondment ? _c('div', {
    staticClass: "d-flex flex-row secondment-card-custom"
  }, [_c('div', {
    staticClass: "w-100 flex-shrink-0 offcanvas-desktop"
  }, [_c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded d-flex justify-content-around",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.cardsList, function (card, idx) {
    return [card.canShow ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c(card.isDisabled ? 'span' : 'router-link', {
      tag: "router-link",
      staticClass: "navi-link py-4",
      class: {
        'cursor-not-allowed': card.isDisabled,
        'cursor-pointer': !card.isDisabled,
        disabled: card.isDisabled
      },
      attrs: {
        "exact-active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: card.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon mr-2"
    }, [_c('i', {
      class: card.icon
    })])])], 1) : _vm._e()];
  })], 2)]), _c('div', {
    staticClass: "flex-row-auto offcanvas-mobile w-300px w-xl-350px"
  }, [_c('div', {
    staticClass: "card card-custom card-stretch"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('country-flag', {
    staticClass: "mr-3",
    attrs: {
      "country-iso": _vm.secondment.country
    }
  }), _c('div', [_vm.secondment.clients.length ? _c('div', {
    staticClass: "font-weight-bold font-size-h4 text-dark-75"
  }, [_vm._v(" " + _vm._s(_vm.client.name) + " ")]) : _vm._e(), _c('span', {
    staticClass: "text-capitalize text-muted"
  }, [_vm._v(_vm._s(this.$t((_vm$countriesMap$_vm$ = _vm.countriesMap[_vm.secondment.country]) === null || _vm$countriesMap$_vm$ === void 0 ? void 0 : _vm$countriesMap$_vm$.name)))])])], 1), _c('div', {
    staticClass: "mt-7"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Status")) + ": "), _c('span', {
    staticClass: "label label-primary label-inline font-weight-bolder text-capitalize label-lg",
    class: {
      'label-warning': _vm.status == 'draft',
      'label-success': _vm.status != 'draft'
    }
  }, [_vm._v(_vm._s(_vm.status))])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Created On")) + ": "), _c('span', {
    staticClass: "font-weight-normal"
  }, [_vm._v(_vm._s(_vm.secondment.created ? _vm.DateService.format(_vm.secondment.created, _vm.localFormatDate) : ""))])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Last Update")) + ": "), _c('span', {
    staticClass: "font-weight-normal"
  }, [_vm._v(_vm._s(_vm.secondment.updated ? _vm.DateService.format(_vm.secondment.updated, _vm.localFormatDate) : ""))])])]), _vm.status == 'draft' ? _c('div', {
    staticClass: "mt-7"
  }, [_c('router-link', {
    staticClass: "btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4",
    attrs: {
      "to": {
        name: 'edit-secondment',
        params: {
          secondmentId: _vm.secondmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Edit assignment")) + " ")])], 1) : _vm._e(), _c('div', {
    staticClass: "py-9"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Date")) + ": "), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" " + _vm._s(_vm.secondment.date_start ? _vm.DateService.format(_vm.secondment.date_start, _vm.localFormatDate) : "") + " ")]), _c('span', {
    staticClass: "svg-icon svg-icon-sm svg-icon-dark mx-1 font-weight-normal"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Navigation/Right-2.svg"
    }
  })], 1), _c('div', {
    staticClass: "font-weight-normal"
  }, [_vm._v(" " + _vm._s(_vm.secondment.date_end ? _vm.DateService.format(_vm.secondment.date_end, _vm.localFormatDate) : "") + " ")])])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignment Duration ")) + " (" + _vm._s(_vm.$t("Days")) + "): "), _c('span', {
    staticClass: "label label-light-dark label-rounded label-inline"
  }, [_vm._v(" " + _vm._s(_vm.secondmentSumDays(_vm.secondment.date_end, _vm.secondment.date_start)))])])]), _vm.status != 'draft' ? _c('div', [_vm.secondmentProgressValue > -1 ? _c('div', {
    staticClass: "py-2"
  }, [_c('div', [_c('p', {
    staticClass: "text-center font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Assignment")) + " " + _vm._s(_vm.$t("Progress")) + ":")]), _c('b-progress', {
    attrs: {
      "max": _vm.progressData.max,
      "height": "1.5rem"
    }
  }, [_c('b-progress-bar', {
    attrs: {
      "value": _vm.progressData.value
    }
  })], 1), _c('p', {
    staticClass: "text-center font-weight-bold"
  }, [_vm._v(" " + _vm._s((_vm.progressData.value / _vm.progressData.max * 100).toFixed(2)) + "% ")])], 1)]) : _vm._e()]) : _vm._e(), _vm.hasManager ? _c('div', [_c('div', {
    staticClass: "separator separator-dashed mb-10 mt-4"
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('Avatar', {
    attrs: {
      "avatar-image": _vm.current_manager[0].avatar,
      "avatar-text": "".concat(_vm.current_manager[0].name)
    }
  }), _c('div', {
    staticClass: "ml-3"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Assignment Manager")) + ":")]), _c('div', {
    staticClass: "font-weight-bold",
    style: {
      wordBreak: 'break-word'
    }
  }, [_vm._v(" " + _vm._s(_vm.current_manager[0].name) + " ")])])], 1), _vm.current_manager[0].email != null ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-normal mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Email")) + ": "), _c('span', {
    staticClass: "text-muted w-75"
  }, [_vm._v(_vm._s(_vm.current_manager[0].email))])])]) : _vm._e(), _vm.current_manager[0].phone != null ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2 font-weight-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Phone")) + ": "), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.current_manager[0].phone))])]) : _vm._e(), _c('div', {
    staticClass: "separator separator-dashed mb-10 mt-4"
  })]) : _vm._e(), _c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.cardsList, function (card, idx) {
    return [card.canShow ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      key: idx,
      staticClass: "navi-item mb-2",
      attrs: {
        "title": card.tooltip
      }
    }, [_c(card.isDisabled ? 'span' : 'router-link', {
      tag: "router-link",
      staticClass: "navi-link py-4",
      class: {
        'cursor-not-allowed': card.isDisabled,
        'cursor-pointer': !card.isDisabled,
        disabled: card.isDisabled
      },
      attrs: {
        "exact-active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: card.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: card.icon
    })]), _c('span', {
      staticClass: "navi-text font-size-lg"
    }, [_vm._v(_vm._s(card.name))])])], 1) : _vm._e()];
  })], 2)])])]), _c('div', {
    staticClass: "flex-row-fluid ml-lg-8"
  }, [_c('div', {
    staticClass: "card card-custom"
  }, [_c('router-view', _vm._g(_vm._b({
    key: _vm.$route.fullPath
  }, 'router-view', _vm.currentDetailComponentProps, false), _vm.currentDetailComponentEvents))], 1)])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }